import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import config from '../../../utils/siteConfig'

const ImageMeta = ({ image }) => {
  if (!image) {
    return null
  }

  return (
    <Helmet>
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={image.src} />
      <meta property="og:image" content={image.src} />
      <meta property="og:image:width" content={image.width} />
      <meta property="og:image:height" content={image.height} />
    </Helmet >
  )
}

ImageMeta.propTypes = {
  image: PropTypes.shape({}),
}

export default ImageMeta
